import React,{useEffect} from "react";
// import "../../assets/css/thankyou.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "./../../components/Footer/Footer";
import Header from "./../../components/Header/Header";
import "../../assets/css/Thankyou.css";
import { useSelector,useDispatch } from "react-redux";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import { AddCartGetAll } from "../../store/productSlice";

const Thankyou = () => {
	//   const Location = useLocation();
	const product = useSelector((state) => state.cart);
	let dispatch = useDispatch();
	let { paymentStatus } = useSelector((state) => state.product);
	const navigate = useNavigate();
	const handleClick = () => {
		navigate("/");
	};

	useEffect(() => {
		localStorage.removeItem("cartItems");
		localStorage.removeItem("Coupon")
		dispatch(AddCartGetAll());
	}, []);

	return (
		<>
			<Header />

			{/* Inner Banner */}
			<InnerBanner />
			{/* Inner Banner */}

			<section className="thankyou">
				<div className="container">
					<div className="happyShopping">
						<h3 className="font-30 fw-600 pb-4">Thank you for Shopping</h3>
						{paymentStatus &&
							paymentStatus?.map((item, index) => (
								<>
									{console.log(item)}
									<div key={index}>
										<p>Order Id: {item?.id}</p>
										<p>Amount: ${Math.round(item?.order_amount + item?.shipping_cost)}</p>
										<p>
											Payment Method:{" "}
											{item?.payment_method == "cod" ? "Cash" : "Credit"}
										</p>
										<p>Status: {item?.order_status}</p>
									</div>
								</>
							))}
						<div className="button-group">
							<button onClick={handleClick} className="btn">
								Continue Shopping
							</button>
						</div>
					</div>
				</div>
			</section>

			<Footer />
		</>
	);
};

export default Thankyou;
