import React, { useState } from "react";
import { useSelector } from "react-redux";
import { WishlistImg } from "../../constant/Index";
import { useDispatch } from "react-redux";
import { getOrderDetails, getAllOrders } from "../../store/productSlice";
import ReactPaginate from "react-paginate";
import { useEffect } from "react";
import Spinner from "../../components/Spinner/Spinner";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const MyOrders = () => {
	const dispatch = useDispatch();
	const [currentPageState, setcurrentPageState] = useState(1);
	const [Amount, setAmount] = useState(0);
	const { purchaseOrder, orderDetails, isLoading } = useSelector(
		(state) => state.product,
	);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const handlePageClick = (data) => {
		let currentPage = data.selected + 1;
		setcurrentPageState(currentPage);
	};
	const pages = Math.ceil(purchaseOrder?.total_size / purchaseOrder?.limit);

	useEffect(() => {
		let data = {
			page: currentPageState,
			limit: 5,
		};
		dispatch(getAllOrders(data));
	}, [currentPageState]);

	const HandleClick = (id,amount) => {
		setAmount(amount);
		let data = {
			id: id,
		};
		dispatch(getOrderDetails(data));
	};

	let discount = orderDetails[0]?.product_details?.discountedprice
		? orderDetails[0]?.product_details?.discountedprice * orderDetails[0]?.qty
		: orderDetails[0]?.product_details?.unit_price * orderDetails[0]?.qty;
	let voucher =
		orderDetails[0]?.order?.coupon_code !== "undefined"
			? orderDetails[0]?.order?.coupon_code
			: "0";
	let shippingCost = orderDetails[0]?.order?.shipping_cost
		? orderDetails[0]?.order?.shipping_cost
		: "0";

	let total = Math.round(discount + parseInt(shippingCost) - voucher);



	// if (isLoading) {
	// 	return (
	// 		<>
	// 			<Box
	// 				sx={{
	// 					display: "flex",
	// 					width: "100%",
	// 					justifyContent: "center",
	// 					marginTop: "30px",
	// 					height: "100%",
	// 				}}
	// 			>
	// 				<CircularProgress />
	// 			</Box>
	// 		</>
	// 	);
	// }

	return (
		<>
			<div className="profile-tabe-wrapper">
				<div className="pro-detail-wrapper">
					<div className="profile-content-heading">
						<h4>My Orders</h4>
					</div>

					{purchaseOrder ? (
						<>
							<div className="order-table-wrapper table-responsive">
								<table>
									<tbody>
										<tr className="row1">
											<th className="order-no">OrderNo</th>
											<th className="order-date">Order Date</th>
											<th className="order-status">Status</th>
											<th className="order-pay">Payment Status</th>
											<th className="order-total">Total</th>
											<th className="details"></th>
										</tr>
										{purchaseOrder &&
											purchaseOrder?.products?.map((orders, index) =>
												index <
												(purchaseOrder.limit
													? purchaseOrder.limit
													: purchaseOrder?.products?.length) ? (
													<tr key={index} className="row2">
														<td className="number">{orders?.id}</td>
														<td className="date">
															{orders?.created_at?.split("T")[0]}
														</td>
														<td className="status">{orders?.order_status}</td>
														{orders?.payment_status === "paid" ? (
															<>
																<td className="paid">
																	<i
																		className="fa fa-check-circle"
																		aria-hidden="true"
																	></i>
																	<span className="paid-status">Paid</span>
																</td>
															</>
														) : (
															<>
																<td className="unpaid">
																	<i
																		className="fa fa-times-circle-o"
																		aria-hidden="true"
																	></i>
																	<span className="paid-status">unpaid</span>
																</td>
															</>
														)}
														<td className="total-price">
															${orders?.order_amount}
														</td>
														<td className="order-details">
															<a
																className="order-details-btn"
																data-toggle="modal"
																data-target="#exampleModalLong"
																onClick={() => HandleClick(orders?.id,orders?.order_amount)}
															>
																view Details
															</a>
														</td>
													</tr>
												) : (
													<div key={index + 1000}></div>
												),
											)}
									</tbody>
								</table>
								<div className="paginationsS">
									<ReactPaginate
										breakLabel="..."
										nextLabel=">"
										previousLabel="<"
										onPageChange={handlePageClick}
										marginPagesDisplayed={0}
										pageRangeDisplayed={4}
										pageCount={pages}
									/>
								</div>
							</div>
						</>
					) : (
						<div className="mt-5">
							<h4>No Order Found</h4>
						</div>
					)}
					<div
						className="modal fade"
						id="exampleModalLong"
						tabIndex="-1"
						role="dialog"
						aria-labelledby="exampleModalLongTitle"
						aria-hidden="true"
					>
						<div className="modal-dialog" role="document">
							<div className="modal-content">
								<div className="model-header pt-5">
									<h5 className="modal-title" id="exampleModalLongTitle">
										Order Details
									</h5>
									<h6>
										Order #{orderDetails[0]?.order_id} was placed on{" "}
										{orderDetails[0]?.created_at?.split("T")[0]}
									</h6>
									<button
										type="button"
										className="close"
										data-dismiss="modal"
										aria-label="Close"
									>
										<span aria-hidden="true">
											<i className="fa fa-times" aria-hidden="true"></i>
										</span>
									</button>
								</div>
								<div className="modal-body">
									{orderDetails?.map((orders, index) => (
										<>
										<div className="product-deatail-wrapper my-3">
										<div className="product-img">
											<figure>
												<img
													src={orders?.product_details?.thumbnail}
													className="img-fluid w-75"
													alt=""
												/>
											</figure>
										</div>
										<div className="product-content">
											<h5 className="product-name">
												{orders?.product_details?.name}
											</h5>
											<h6 className="product-size">
												$
												{orders?.product_details?.discountedprice
													? orders?.product_details?.discountedprice
													: orders?.product_details?.unit_price}
											</h6>
											<h4 className="stocks">
												Quantity : &nbsp;
												<span style={{ color: "#1aac7a" }}>
													({orders?.qty})
												</span>
											</h4>
										</div>
									</div>
										</>
									))
									
									}
									<div className="order-details">
										<div className="order-details-wrapper mt-5">
											{/* <h6>
												<span>Sub Total :</span>
												<span className="date">
											
									
													$
													{orderDetails[0]?.product_details?.discountedprice
														? orderDetails[0]?.product_details
																?.discountedprice * orderDetails[0]?.qty
														: orderDetails[0]?.product_details?.unit_price *
														  orderDetails[0]?.qty}
												</span>
											</h6> */}
											<h6>
												<span>Voucher Code Applied :</span>
												{console.log(orderDetails[0])}
												<span className="subtotal">
													$
													{orderDetails[0]?.order?.discount_amount
														? orderDetails[0]?.order?.discount_amount
														: "0"}
												</span>
											</h6>
											<h6>
												<span>Shipping Cost :</span>
												<span className="tax-fee">
													$
													{orderDetails[0]?.order?.shipping_cost
														? orderDetails[0]?.order?.shipping_cost
														: "0"}
												</span>
											</h6>
											{/* <h6>
												<span>Shipping Address:</span>
												<span className="shipping-fee">
													<p
														className="fw-400"
														dangerouslySetInnerHTML={{
															__html: orderDetails[0]?.order
																?.shipping_address_data
																? orderDetails[0]?.order?.shipping_address_data?.slice(
																		0,
																		40,
																  )
																: "No Address",
														}}
													/>
												</span>
											</h6> */}
											<h6>
												<span>Total Amount :</span>
												<span className="Coupon">${Amount}</span>
											</h6>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default MyOrders;
