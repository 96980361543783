import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import { Avatar, headlogo } from "../../constant/Index";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProducts,
  HomeCategories,
  SendCatId,
  SubCategories,
  SendSearchVal,
  AddCartGetAll,
} from "../../store/productSlice";
import menu3 from "../../assets/img/menu3.jpg";
import menu4 from "../../assets/img/menu4.jpg";

import { SearchIcon } from "../../constant/Index";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const Header = ({ currentPageState }) => {
  const [loader, setLoader] = useState(false);
  const [run, setRun] = useState(0);
  const [mainSearch, setMainSearch] = useState("");
  const [dropdown, setDropdown] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let location = useLocation();
  const searching = useSelector((state) => state.product?.SearchData);
  const homeCategory = useSelector((state) => state.product?.HomeCatData);
  const subProduct = useSelector((state) => state.product?.SubData);
  const subcat = useSelector((state) => state.product?.SubCategory);
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    dispatch(HomeCategories());
  }, []);
  const isLogin = JSON.parse(localStorage.getItem("Token"));
  const { Cart } = useSelector((state) => state.product);
  const [Show, setShow] = useState(false);
  const [filter, setFilter] = useState("");
  const subCategoryChoose = async (e) => {
    setLoader(true);
    await dispatch(SubCategories(`${e}`));
    setLoader(false);
  };

  useEffect(() => {
    subCategoryChoose();
  }, []);

  const handleSub = (id, e) => {
    e.preventDefault();
    setDropdown(false);
    dispatch(SendCatId(id));
    setTimeout(() => {
      navigate(`/shop`);
    }, 1000);
  };

  const HandleSeArch = () => {
    dispatch(SendSearchVal(mainSearch));
    navigate(`/shop`);
  };

  const [view, setView] = useState(false);

  const [newStateCheck, setNewStateCheck] = useState(null);
  const showAndHideFunc2 = (e, f) => {
    setView(e);
    setNewStateCheck(f);
  };

  const links = ["link1", "link2", "link3"];
  const [active, setActive] = useState(null);

  const length = 1;

  // const googleTranslateElementInit = () => {
  // 	new window.google.translate.TranslateElement(
  // 		{
  // 			pageLanguage: "en",
  // 			layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT,
  // 		},
  // 		"trans",
  // 	);
  // };
  // useEffect(() => {
  // 	const unsub = () => {
  // 		setTimeout(() => {
  // 			var addScript = document.createElement("script");
  // 			addScript.setAttribute(
  // 				"src",
  // 				"https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit",
  // 			);
  // 			document.body.appendChild(addScript);
  // 			window.googleTranslateElementInit = googleTranslateElementInit;
  // 		}, 1000);
  // 	};
  // 	return clearTimeout(unsub);
  // }, []);

  // const googleTranslateElementInit = () => {
  // 	new window.google.translate.TranslateElement(
  // 		{
  // 			pageLanguage: "en",
  // 			layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT,
  // 		},
  // 		"trans",
  // 	);
  // };

  // useEffect(() => {
  // 	var addScript = document.createElement("script");
  // 	addScript.setAttribute(
  // 		"src",
  // 		"https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit",
  // 	);
  // 	document.body.appendChild(addScript);
  // 	window.googleTranslateElementInit = googleTranslateElementInit;
  // }, [googleTranslateElementInit]);

  // const onSearchSubmitHandler = (e) => {
  // 	e.preventDefault();
  // 	dispatch(
  // 		fetchProducts({
  // 			name: searchSlug,
  // 			page: 1,
  // 		}),
  // 	);
  // 	navigate(`/shop`, {
  // 		state: {
  // 			slug: searchSlug,
  // 		},
  // 	});
  // };

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT,
      },
      "trans"
    );
  };

  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, [googleTranslateElementInit]);

  return (
    <>
      <header id="header">
        <div className="secondary-header-wrapper">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-3">
                <div className="header-cta-wrapper">
                  <ul>
                    <li className="logo-wrapper">
                      <Link to="/">
                        <img
                          src={headlogo}
                          className="img-fluid logo_image"
                          alt=""
                        />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-5">
                <ul>
                  <li className="input-wrapper search mx-auto">
                    <input type="text" name="" id="" placeholder="Search" />
                    <button>
                      <img src={SearchIcon} alt="SearchBar" />
                    </button>
                  </li>
                </ul>
              </div>

              <div className="col-lg-4">
                <div className="social-icons-wrapper">
                  <ul>
                    <li>
                      {/* <select defaultValue={"Language"} name="" id="">
												<option value="Language">Language</option>
											</select> */}
                      <>
                        <div className="translate" id="trans"></div>
                      </>
                    </li>
                    {isLogin ? (
                      <Link to="/profile">
                        <img
                          className="img-fluid"
                          width={30}
                          src={user?.image}
                          alt=""
                          style={{
                            borderRadius: "50%",
                            width: "30px",
                            height: "30px",
                            // border: "1px solid #434343",
                          }}
                        />
                      </Link>
                    ) : (
                      <li>
                        <Link to="/signin">
                          {/* <i
														className="fa fa-user"
														style={{ color: "#434343" }}
													></i> */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22.225"
                            height="22.225"
                            viewBox="0 0 22.225 22.225"
                          >
                            <path
                              id="Icon_awesome-user-alt"
                              data-name="Icon awesome-user-alt"
                              d="M11.113,12.5A6.251,6.251,0,1,0,4.862,6.251,6.253,6.253,0,0,0,11.113,12.5Zm5.556,1.389H14.277a7.556,7.556,0,0,1-6.329,0H5.556A5.556,5.556,0,0,0,0,19.447v.695a2.084,2.084,0,0,0,2.084,2.084H20.142a2.084,2.084,0,0,0,2.084-2.084v-.695A5.556,5.556,0,0,0,16.669,13.891Z"
                              fill="#434343"
                            />
                          </svg>
                        </Link>
                      </li>
                    )}
                    <li>
                      <Link to="/cart">
                        {/* <i
													className="fa fa-shopping-cart"
													style={{ color: "#434343" }}
												></i> */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22.832"
                          height="20.295"
                          viewBox="0 0 22.832 20.295"
                        >
                          <path
                            id="Icon_awesome-shopping-cart"
                            data-name="Icon awesome-shopping-cart"
                            d="M20.934,11.944,22.808,3.7a.951.951,0,0,0-.928-1.162H6.311L5.947.761A.951.951,0,0,0,5.015,0H.951A.951.951,0,0,0,0,.951v.634a.951.951,0,0,0,.951.951h2.77L6.506,16.15a2.22,2.22,0,1,0,2.658.34h8.31a2.219,2.219,0,1,0,2.521-.412l.219-.962a.951.951,0,0,0-.928-1.162H8.646l-.259-1.268h11.62A.951.951,0,0,0,20.934,11.944Z"
                            fill="#434343"
                          />
                        </svg>

                        <span className="total-cart-no">
                          {Cart?.length ? Cart?.length : 0}
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="main-header-wrapper">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-2">
                {/* <div className="logo-wrapper">
									<Link to="/">
										<img src={headlogo} className="img-fluid" alt="" />
									</Link>
								</div> */}
              </div>
              <div className="col-8">
                <div className="navbar-items-wrapper">
                  <ul className="navbar-nav">
                    {/* {homeCategory?.data?.map((catData, index) => {
											return (
												<li
													key={index}
													className="nav-item"
													onMouseEnter={() => {
														setDropdown(true);
													}}
												>
													<Link to="/" className="nav-links">
														<div className={dropdown ? "dropdown" : "dropdown"}>
															<Link
																className="btn btn-secondary dropdown-toggle Customdrop"
																to="#"
																role="button"
																id="dropdownMenuLink"
																data-toggle="dropdown"
																aria-haspopup="true"
																aria-expanded="false"
															>
																{catData?.name}
															</Link>

															<div
																className="dropdown-menu cusdropdown"
																aria-labelledby="dropdownMenuLink"
															>
																<div className="mainColumns">
																	{catData?.childes.map((sub, index) => {
																		return (
																			<>
																				<div className="lnkss">
																					<div className="columnss">
																						<Link
																							onClick={(e) =>
																								handleSub(sub?.id, e)
																							}
																							key={index}
																							className="dropdown-item"
																							to="/shop"
																						>
																							{sub?.name}
																						</Link>
																						{sub?.childes.length > 0 ? (
																							<>
																								{sub?.childes?.map(
																									(item, index) => {
																										return (
																											<li key={index}>
																												<div
																													onClick={(e) =>
																														handleSub(
																															sub?.id,
																															e,
																														)
																													}
																													key={index}
																													className={
																														dropdown
																															? "dropdown"
																															: "dropdown"
																													}
																												>
																													<span className="sub-child">
																														{item?.name}
																													</span>
																												</div>
																											</li>
																										);
																									},
																								)}
																							</>
																						) : null}
																					</div>
																				</div>
																			</>
																		);
																	})}
																</div>
															</div>
														</div>
													</Link>
												</li>
											);
										})} */}
                    <li
                      className="nav-item "
                      onMouseEnter={() => {
                        setDropdown(true);
                      }}
                    >
                      {/* <Link to="/" className="nav-links">
                        <div className={dropdown ? "dropdown" : "dropdown"}>
                          <Link
                            className="btn btn-secondary dropdown-toggle Customdrop"
                            to="#"
                            role="button"
                            id="dropdownMenuLink"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Health & Wellness
                          </Link>

                          <div
                            className="dropdown-menu cusdropdown"
                            aria-labelledby="dropdownMenuLink">
                            <div className="mainColumns">
                              <div className="lnkss">
                                <div className="columnss">
                                  <Link className="dropdown-item" to="/shop">
                                    Vitamine & Supplements
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link> */}
                      {/* className={(newStateCheck === 1) ? "button_shashke" : "maaaz"}  */}
                      <div class="dropdown health-drop ">
                        <button
                          className={
                            view == true && newStateCheck == 1
                              ? "button_shashke"
                              : null
                          }
                          onClick={() => {
                            showAndHideFunc2(!view, 1);
                          }}
                          // class="btn btn-secondary dropdown-toggle"
                          // type="button"
                          // id="dropdownMenu2"
                          // data-toggle="dropdown"
                          // aria-haspopup="true"
                          // aria-expanded="false"
                        >
                          Health & Wellness{" "}
                          <i class="fa fa-caret-down" aria-hidden="true"></i>
                        </button>
                        {/* <div
                          class="dropdown-menu health-drop sec-drop"
                          aria-labelledby="dropdownMenu2"
                        ></div> */}
                      </div>
                    </li>
                    <li
                      className="nav-item"
                      onMouseEnter={() => {
                        setDropdown(true);
                      }}
                    >
                      {/* <Link to="/" className="nav-links">
                        <div className={dropdown ? "dropdown" : "dropdown"}>
                          <Link
                            className="btn btn-secondary dropdown-toggle Customdrop"
                            to="#"
                            role="button"
                            id="dropdownMenuLink"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Vitamins & Supplements
                          </Link>

                          {/* <div
                            className="dropdown-menu cusdropdown"
                            aria-labelledby="dropdownMenuLink"
                          >
                            <div className="mainColumns">
                              <div className="lnkss">
                                <div className="columnss">
                                  <Link className="dropdown-item" to="/shop">
                                    Vitamine & Supplements
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div> */}
                      {/* </div>
                      </Link> */}
                      <div class="dropdown health-drop ">
                        <button
                          className={
                            view == true && newStateCheck == 2
                              ? "button_shashke"
                              : null
                          }
                          onClick={() => {
                            showAndHideFunc2(!view, 2);
                          }}
                          // class="btn btn-secondary dropdown-toggle"
                          // type="button"
                          // id="dropdownMenu2"
                          // data-toggle="dropdown"
                          // aria-haspopup="true"
                          // aria-expanded="false"
                        >
                          Vitamins & Supplements{" "}
                          <i class="fa fa-caret-down" aria-hidden="true"></i>
                        </button>
                        {/* <div
                          class="dropdown-menu health-drop sec-drop"
                          aria-labelledby="dropdownMenu2"
                        ></div> */}
                      </div>
                    </li>
                    <li
                      className="nav-item"
                      onMouseEnter={() => {
                        setDropdown(true);
                      }}
                    >
                      {/* <Link to="/" className="nav-links">
                        <div className={dropdown ? "dropdown" : "dropdown"}>
                          <Link
                            className="btn btn-secondary dropdown-toggle Customdrop"
                            to="#"
                            role="button"
                            id="dropdownMenuLink"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Sports Nutrition
                          </Link>

                          {/* <div
                            className="dropdown-menu cusdropdown"
                            aria-labelledby="dropdownMenuLink"
                          >
                            <div className="mainColumns">
                              <div className="lnkss">
                                <div className="columnss">
                                  <Link className="dropdown-item" to="/shop">
                                    Vitamine & Supplements
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div> */}
                      {/* </div>
                      </Link> */}

                      <div class="dropdown health-drop ">
                        <button
                          className={
                            view == true && newStateCheck == 3
                              ? "button_shashke"
                              : null
                          }
                          onClick={() => {
                            showAndHideFunc2(!view, 3);
                          }}
                          // class="btn btn-secondary dropdown-toggle"
                          // type="button"
                          // id="dropdownMenu2"
                          // data-toggle="dropdown"
                          // aria-haspopup="true"
                          // aria-expanded="false"
                        >
                          Sports Nutrition{" "}
                          <i class="fa fa-caret-down" aria-hidden="true"></i>
                        </button>
                        {/* <div
                          class="dropdown-menu health-drop sec-drop"
                          aria-labelledby="dropdownMenu2"
                        ></div> */}
                      </div>
                    </li>
                    <li
                      className="nav-item"
                      onMouseEnter={() => {
                        setDropdown(true);
                      }}
                    >
                      {/* <Link to="/" className="nav-links">
                        <div className={dropdown ? "dropdown" : "dropdown"}>
                          <Link
                            className="btn btn-secondary dropdown-toggle Customdrop"
                            to="#"
                            role="button"
                            id="dropdownMenuLink"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Herbs & Natural Remedies
                          </Link>

                          {/* <div
                            className="dropdown-menu cusdropdown"
                            aria-labelledby="dropdownMenuLink"
                          >
                            <div className="mainColumns">
                              <div className="lnkss">
                                <div className="columnss">
                                  <Link className="dropdown-item" to="/shop">
                                    Vitamine & Supplements
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div> */}
                      {/* </div>
                      </Link> */}
                      <div class="dropdown health-drop ">
                        <button
                          className={
                            view == true && newStateCheck == 4
                              ? "button_shashke"
                              : null
                          }
                          onClick={() => {
                            showAndHideFunc2(!view, 4);
                          }}
                          // class="btn btn-secondary dropdown-toggle"
                          // type="button"
                          // id="dropdownMenu2"
                          // data-toggle="dropdown"
                          // aria-haspopup="true"
                          // aria-expanded="false"
                        >
                          Herbs & Natural Remedies{" "}
                          <i class="fa fa-caret-down" aria-hidden="true"></i>
                        </button>
                        {/* <div
                          class="dropdown-menu health-drop sec-drop"
                          aria-labelledby="dropdownMenu2"
                        ></div> */}
                      </div>
                    </li>
                    <li
                      className="nav-item"
                      onMouseEnter={() => {
                        setDropdown(true);
                      }}
                    >
                      {/* <Link to="/" className="nav-links">
                        <div className={dropdown ? "dropdown" : "dropdown"}>
                          <Link
                            className="btn btn-secondary dropdown-toggle Customdrop"
                            to="#"
                            role="button"
                            id="dropdownMenuLink"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Food and Drinks
                          </Link>
                          {/* 
                          <div
                            className="dropdown-menu cusdropdown"
                            aria-labelledby="dropdownMenuLink"
                          >
                            <div className="mainColumns">
                              <div className="lnkss">
                                <div className="columnss">
                                  <Link className="dropdown-item" to="/shop">
                                    Vitamine & Supplements
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div> */}
                      {/* </div>
                      </Link> */}
                      <div class="dropdown health-drop ">
                        <button
                          className={
                            view == true && newStateCheck == 5
                              ? "button_shashke"
                              : null
                          }
                          onClick={() => {
                            showAndHideFunc2(!view, 5);
                          }}
                          // class="btn btn-secondary dropdown-toggle"
                          // type="button"
                          // id="dropdownMenu2"
                          // data-toggle="dropdown"
                          // aria-haspopup="true"
                          // aria-expanded="false"
                        >
                          Food & Drinks{" "}
                          <i class="fa fa-caret-down" aria-hidden="true"></i>
                        </button>
                        {/* <div
                          class="dropdown-menu health-drop sec-drop"
                          aria-labelledby="dropdownMenu2"
                        ></div> */}
                      </div>
                    </li>

                    {/* <li className="nav-item">
											<div className="dropdown ">
												<Link
													className="btn btn-secondary"
													to="/all-categories"
													// role="button"
													// id="dropdownMenuLink"
													// data-toggle="dropdown"
													// aria-haspopup="true"
													// aria-expanded="true"
												>
													Others
												</Link>

												
											</div>
										</li> */}
                    {/* <!-- <li className="nav-item"><Link to="shop.php" className="nav-links">Health & wellness</Link></li> -->
                            <!-- <li className="nav-item"><Link to="shop.php" className="nav-links">Herbs & Natural Remedies</Link></li> --> */}
                    <li className="nav-item">
                      {/* <Link to="/shop" className="nav-links">
                        Offers
                      </Link> */}
                      <div class="dropdown health-drop ">
                        <button
                          className={
                            view == true && newStateCheck == 6
                              ? "button_shashke"
                              : null
                          }
                          onClick={() => {
                            showAndHideFunc2(!view, 6);
                          }}
                          // class="btn btn-secondary dropdown-toggle"
                          // type="button"
                          // id="dropdownMenu2"
                          // data-toggle="dropdown"
                          // aria-haspopup="true"
                          // aria-expanded="false"
                        >
                          Offers{" "}
                          <i class="fa fa-caret-down" aria-hidden="true"></i>
                        </button>
                        {/* <div
                          class="dropdown-menu health-drop sec-drop"
                          aria-labelledby="dropdownMenu2"
                        ></div> */}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-2">

              </div>
            </div>
          </div>
        </div>
      </header>

      <div className={Show ? "search_box active" : "search_box"}>
        <div className="container">
          <div className="close_btn">
            <button className="close" onClick={() => setShow(false)}>
              x
            </button>
          </div>

          <div className="form-group">
            <input
              className="form-control"
              value={mainSearch}
              onChange={(e) => setMainSearch(e.target.value)}
              placeholder="Search here..."
            />

            <>
              <button
                className="btn"
                type="submit"
                onClick={() => HandleSeArch()}
              >
                <i className="fa fa-search" aria-hidden="true"></i>
              </button>
            </>
          </div>
        </div>
      </div>
      {(view == true) & (newStateCheck > 0) ? (
        <div className="sec-drop">
          <div className="container-fluid">
            <div className="row">
              {/* First col  */}
              <div className="col-md-2">
                <div className="drop-down-head">
                  <div className="div-heding">
                    <h3>Health & Wellness</h3>
                  </div>
                  <div className="div-ul">
                    <ul>
                      <li>
                        <Link to="/">Immunity</Link>
                      </li>
                      <li>
                        <Link to="/">Antioxidants</Link>
                      </li>
                      <li>
                        <Link to="/">Men's Health</Link>
                      </li>
                      <li>
                        <Link to="/">Women's Health</Link>
                      </li>
                      <li>
                        <Link to="/">Children's Health</Link>
                      </li>
                      <li>
                        <Link to="/">Heart Health</Link>
                      </li>
                      <li>
                        <Link to="/">Sexual Health</Link>
                      </li>
                      <li>
                        <Link to="/">Sleep & Relaxation</Link>
                      </li>
                      <li>
                        <Link to="/">Stress & Anxiety</Link>
                      </li>
                      <li>
                        <Link to="/">Digestive Health</Link>
                      </li>
                      <li>
                        <Link to="/">Brain & Memory</Link>
                      </li>
                      <li>
                        <Link to="/">Joint Support</Link>
                      </li>
                      <li>
                        <Link to="/">Blood Sugar Support</Link>
                      </li>
                    </ul>
                  </div>

                  {/* <div className="div-heding">
                  <h3>Diet</h3>
                </div>
                <div className="div-ul">
                  <ul>
                    <li><Link to="/">Simmply dummy</Link></li>
                    <li><Link to="/">Simmply dummy</Link></li>
                  </ul>
                </div> */}

                  {/* <div className="div-heding">
                  <h3>Testo Booster </h3>
                </div>
                <div className="div-ul">
                  <ul>
                    <li><Link to="/">Simmply dummy</Link></li>
                    <li><Link to="/">Simmply dummy</Link></li>
                    <li><Link to="/">Simmply dummy</Link></li>
                  </ul>
                </div> */}
                </div>
              </div>
              {/* Second col  */}
              <div className="col-md-2">
                <div className="drop-down-head">
                  <div className="div-heding">
                    <h3>Vitamins & Supplements</h3>
                  </div>
                  <div className="div-ul">
                    <ul>
                      <li className="for-bold-li">
                        <Link to="/">Multivitamins</Link>
                      </li>
                      <li>
                        <Link to="/">Vitamin A</Link>
                      </li>
                      <li>
                        <Link to="/">Vitamin B</Link>
                      </li>
                      <li>
                        <Link to="/">Vitamin C</Link>
                      </li>
                      <li>
                        <Link to="/">Vitamin D</Link>
                      </li>
                      <li>
                        <Link to="/">Vitamin E</Link>
                      </li>
                      <li className="for-bold-li for-up">
                        <Link to="/">Minerals</Link>
                      </li>
                      <li>
                        <Link to="/">Calcium</Link>
                      </li>
                      <li>
                        <Link to="/">Iron</Link>
                      </li>
                      <li>
                        <Link to="/">Magnesium</Link>
                      </li>
                      <li>
                        <Link to="/">Zinc</Link>
                      </li>
                      <li>
                        <Link to="/">Selenium</Link>
                      </li>
                      <li>
                        <Link to="/">Potasium</Link>
                      </li>
                      <li className="for-bold-li for-up">
                        <Link to="/">Supplements</Link>
                      </li>
                      <li>
                        <Link to="/">Antioxidants</Link>
                      </li>
                      <li>
                        <Link to="/">Omegas</Link>
                      </li>
                      <li>
                        <Link to="/">Collagen</Link>
                      </li>
                      <li>
                        <Link to="/">Co Q10</Link>
                      </li>
                      <li>
                        <Link to="/">Garlic</Link>
                      </li>
                      <li>
                        <Link to="/">Ginkgo Biloba</Link>
                      </li>
                      <li>
                        <Link to="/">Aloe Vera Products</Link>
                      </li>
                    </ul>
                  </div>

                  {/* <div className="div-heding">
                  <h3>Vitamins & Minerals</h3>
                </div>
                <div className="div-ul">
                  <ul>
                    <li><Link to="/">Simmply dummy</Link></li>
                    <li><Link to="/">Simmply dummy</Link></li>
                  </ul>
                </div> */}
                </div>
              </div>
              {/* Third col  */}
              <div className="col-md-2">
                <div className="drop-down-head">
                  <div className="div-heding">
                    <h3>Sports Nutrition</h3>
                  </div>
                  <div className="div-ul">
                    <ul>
                      <li>
                        <Link to="/">Protein</Link>
                      </li>
                      <li>
                        <Link to="/">Amino Acids</Link>
                      </li>
                      <li>
                        <Link to="/">Workout Boosters</Link>
                      </li>
                      <li>
                        <Link to="/">Creatine</Link>
                      </li>
                      <li>
                        <Link to="/">Gainer</Link>
                      </li>
                      <li>
                        <Link to="/">Energy & EnEndurance</Link>
                      </li>
                      <li>
                        <Link to="/">Bars/Snacks/Drinks</Link>
                      </li>
                      <li>
                        <Link to="/">Testo Boosters</Link>
                      </li>
                      <li>
                        <Link to="/">Diet/Fatloss</Link>
                      </li>
                      <li>
                        <Link to="/">Sports Accessories</Link>
                      </li>
                    </ul>
                  </div>

                  {/* <div className="div-heding">
                    <h3>Drinks</h3>
                  </div> */}
                  <div className="div-ul">
                   
                  </div>

                  {/* <div className="div-heding">
                  <h3>Gainers</h3>
                </div>
                <div className="div-ul">
                  <ul>
                    <li><Link to="/">Simmply dummy</Link></li>
                    <li><Link to="/">Simmply dummy</Link></li>
                    <li><Link to="/">Simmply dummy</Link></li>
                  </ul>
                </div> */}

                  {/* <div className="div-heding">
                  <h3>Creatine</h3>
                </div>
                <div className="div-ul">
                  <ul>
                    <li><Link to="/">Simmply dummy</Link></li>
                  </ul>
                </div> */}
                </div>
              </div>

              {/* Fourth col  */}
              <div className="col-md-2">
                <div className="drop-down-head">
                  <div className="div-heding">
                    <h3>Herbs & Natural Remedies</h3>
                  </div>
                  <div className="div-ul">
                    <ul>
                      <li>
                        <Link to="/">Ashwagandha</Link>
                      </li>
                      <li>
                        <Link to="/">Ginseng</Link>
                      </li>
                      <li>
                        <Link to="/">Maca</Link>
                      </li>
                      <li>
                        <Link to="/">Turmeric</Link>
                      </li>
                      <li>
                        <Link to="/">Herbal Teas</Link>
                      </li>
                      <li>
                        <Link to="/">Saw Palmetto</Link>
                      </li>
                      <li>
                        <Link to="/">Ginkgo Biloba</Link>
                      </li>
                    </ul>
                  </div>

                  

                  
                </div>
              </div>

              {/* five col  */}
              <div className="col-md-2">
                {/* <div className="image-of-drop-down">
                  <img className="img-fluid" src={menu3}></img>
                </div> */}
                <div className="div-heding">
                    <h3>Drinks & Foods</h3>
                  </div>
                  <div className="div-ul">
                    <ul>
                    <li className="for-bold-li">
                        <Link to="/">Food</Link>
                      </li>
                      <li>
                        <Link to="/">Breakfast</Link>
                      </li>
                      <li>
                        <Link to="/">Dried fruit, Nuts & seeds</Link>
                      </li>
                      <li>
                        <Link to="/">Snacks</Link>
                      </li>
                      <li>
                        <Link to="/">Superfoods</Link>
                      </li>
                      <li>
                        <Link to="/">Rice, Pasta, Grains & Pulses</Link>
                      </li>
                      <li>
                        <Link to="/">Vegan Food</Link>
                      </li>
                      <li>
                        <Link to="/">Gluten Free</Link>
                      </li>
                      <li>
                        <Link to="/">Free From Food</Link>
                      </li>

                  
                    <li className="for-bold-li for-up">
                        <Link to="/">Drinks</Link>
                      </li>
                      <li>
                        <Link to="/">Alcohol Free Drinks</Link>
                      </li>
                      <li>
                        <Link to="/">Coffee & Substitutes</Link>
                      </li>
                      <li>
                        <Link to="/">Juices</Link>
                      </li>
                      <li>
                        <Link to="/">Milk & Dairy Free</Link>
                      </li>
                      <li>
                        <Link to="/">Natural Energy Drinks</Link>
                      </li>
                      <li>
                        <Link to="/">Teas & Beverages</Link>
                      </li>
                  
                    </ul>
                  </div>
              </div>
              {/* Six col  */}
              <div className="col-md-2">
                {/* <div className="image-of-drop-down">
                  <img className="img-fluid" src={menu4}></img>
                </div> */}
                <div className="div-heding">
                    <h3>Offers</h3>
                  </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Header;
