import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../assets/css/signup.css";
import "../../assets/css/signin.css";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import {
	AuthImg,
	FacebookImg,
	GoogleImg,
	TwitterImg,
} from "../../constant/Index";
import { useSelector, useDispatch } from "react-redux";
import { SendOtp } from "../../store/auth/authSlice";
import { forgot } from "../../store/auth/authSlice";

const Otp = () => {
	const [otp, setOtp] = useState("");
	const [identity, setIdentity] = useState("");
	let navigate = useNavigate();
	const dispatch = useDispatch();
	const { isSuccess, message, token } = useSelector((state) => state.auth);
	const StoreEmail = sessionStorage.getItem("email");

	const formData = new FormData();
	// formData.set("identity", identity);
	formData.set("otp", otp);
	formData.set("identity", StoreEmail);

	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(SendOtp(formData));
	};

	useEffect(() => {
		if (message == "otp verified.") {
			return navigate("/create-pass"), sessionStorage.setItem("token", token);
		}
	}, [message]);

	const HandleResent = (e) => {
		e.preventDefault();
		dispatch(forgot(formData));
	};

	return (
		<>
			{/* Header Sec */}
			<Header />
			{/* Header Sec */}

			{/* Inner Banner Sec */}
			<InnerBanner />
			{/* Inner Banner Sec */}

			<section className="register-user">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<div className="register-img">
								<img src={AuthImg} className="img-fluid" alt="" />
								<div className="text-on-img text-center text-white">
									<h3 className="mb-4 font-weight-normal">
										Welcome to Nutritions24
									</h3>
									<p className="mb-4">
										Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
										do eiusmod tempor incididunt ut labore.
									</p>
									<div className="get-connected mb-4">
										<span>Get Connected With</span>
									</div>
									<div className="social-icons mb-5">
										<ul className="row justify-content-center">
											<li>
												<Link to="#">
													<img
														className="img-fluid twitter"
														src={TwitterImg}
														alt=""
													/>
												</Link>
											</li>
											<li>
												<Link to="#">
													<img
														className="img-fluid facebook"
														src={FacebookImg}
														alt=""
													/>
												</Link>
											</li>
											<li>
												<Link to="#">
													<img
														className="img-fluid google"
														src={GoogleImg}
														alt=""
													/>
												</Link>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className="auth-btn text-right">
								<Link to="/signin" className="btn light-btn mr-2">
									Sign in
								</Link>
								<Link to="/signup" className="btn">
									Sign up
								</Link>
							</div>
							<div className="signup-form">
								<h2 className="mb-4">OTP Code</h2>
								<p className="mb-4">
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore.
								</p>
								<hr />
								<form method="POST" onSubmit={handleSubmit}>
									<label htmlFor="" className="mt-3">
										Enter OTP
									</label>
									<div className="row mt-2 otp-section">
										<div className="col">
											<input
												id="otp"
												name="otp"
												value={otp}
												onChange={(e) => setOtp(e.target.value)}
												type="text"
												maxLength="4"
												minLength="4"
											/>
											{/* <input
                        id="email"
                        name="email"
                        value={identity}
                        onChange={(e) => setIdentity(e.target.value)}
                        type="email"
                      /> */}
										</div>
										{/* <div className="col">
                      <input type="text" maxlength="1" />
                    </div>
                    <div className="col">
                      <input type="text" maxlength="1" />
                    </div>
                    <div className="col">
                      <input type="text" maxlength="1" />
                    </div>
                    <div className="col">
                      <input type="text" maxlength="1" />
                    </div>
                    <div className="col">
                      <input type="text" maxlength="1" />
                    </div> */}
										<div className="col-md-12 text-center mt-3">
											<button onClick={HandleResent}>Resend Code</button>
										</div>
										<div className="col-md-12 mt-4">
											<button className="btn w-100" type="submit">
												Send
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* Header Sec */}
			<Footer />
			{/* Header Sec */}
		</>
	);
};

export default Otp;
