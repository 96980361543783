import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { add } from "../../store/cartSlice";
import {
	AddCart,
	AddCartGetAll,
	fetchRelatedProduct,
} from "../../store/productSlice";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import { toast } from "react-toastify";

const RelatedProduct = () => {
	let { id } = useParams();
	const [loader, setLoader] = useState(false);
	const { RelatedProduct } = useSelector((state) => state.product);
	const { Cart, AddData } = useSelector((state) => state.product);

	let dispatch = useDispatch();

	const get = async () => {
		setLoader(true);
		await dispatch(fetchRelatedProduct(id));
		setLoader(false);
	};

	useEffect(() => {
		get();
	}, [id]);

	const handleAdd = (id) => {
		let checkItemAlreadyExist = Cart.filter((val) => val?.product_id === id);
		if (checkItemAlreadyExist.length > 0) {
			toast.error("Item Already Exist in Cart!!!");
		} else {
			let quantity = 1;
			const formData = new FormData();
			formData.append("id", id);
			formData.append("quantity", quantity);
			dispatch(AddCart(formData));
		}
	};

	useEffect(() => {
		dispatch(AddCartGetAll());
	}, [AddData]);

	if (loader) {
		return (
			<>
				<Box
					sx={{
						display: "flex",
						width: "100%",
						justifyContent: "center",
						marginTop: "30px",
						height: "100%",
					}}
				>
					<CircularProgress />
				</Box>
			</>
		);
	}

	return (
		<>
			{RelatedProduct?.length == 0 ? (
				<div className="txt text-center w-100">
					<h4>0 Related products</h4>
				</div>
			) : (
				<>
					{RelatedProduct?.slice(0, 4).map((related, index) => {
						return (
							<div className="col-lg-3" key={index}>
								<div className="single-product-card-wrapper">
									{related?.current_stock <= 0 ? (
										<>
											{console.log(related?.current_stock)}
											<Chip
												label="Out Of Stock"
												style={{
													position: "absolute",
													top: "30px",
													left: "30px",
													background: "red",
													color: "white",
													width: "37%",
													height: "27px",
												}}
											/>
										</>
									) : null}
									<Link to={`/product-detail/${related?.id}`}>
										<div className="single-producrt-img-wrapper">
											<figure>
												<img
													src={related?.thumbnail}
													className="img-fluid"
													alt=""
												/>
											</figure>
										</div>
										<div className="single-product-content-wrapper">
											{/* <h6 className="single-product-category">Acai Berry Plus</h6> */}
											<h5 className="single-product-name">{related?.name}</h5>
											<div className="review-wrapper">
												<ul>
													{related?.rating[0]?.average ? (
														<>
															{[
																...Array(
																	parseInt(
																		related?.rating[0]?.average.split(".")[0] ||
																			0,
																	),
																).keys(),
															].map((starrr) => {
																return (
																	<li key={`star_${starrr}`}>
																		<i className="fa fa-star"></i>
																	</li>
																);
															})}
														</>
													) : (
														<>
															<i class="fa fa-star-o" aria-hidden="true"></i>
															<i class="fa fa-star-o" aria-hidden="true"></i>
															<i class="fa fa-star-o" aria-hidden="true"></i>
															<i class="fa fa-star-o" aria-hidden="true"></i>
															<i class="fa fa-star-o" aria-hidden="true"></i>
														</>
													)}
													{/* <li>
                          <span className="total-reviews">( 119 reviews )</span>
                        </li> */}
												</ul>
											</div>
											<div className="price-wrapper">
												<h6 className="price">
													$
													{related?.discountedprice !== 0
														? Math.round(related?.discountedprice)
														: related?.unit_price}
													<span className="regular-price">
														$
														{related?.discountedprice == 0
															? related?.discountedprice
															: related?.unit_price}
													</span>
												</h6>
											</div>
											<div className="cart-button-wrapper">
												{related?.current_stock == 0 ? (
													<>
														<button
															type="button"
															className="btn form-control"
															style={{
																background: "#1aac7a",
																color: "white",
																cursor: "not-allowed",
															}}
															disabled
														>
															<i className="fa fa-shopping-cart"></i>
															&nbsp; Add To Cart
														</button>
													</>
												) : (
													<>
														<button
															type="button"
															onClick={() => handleAdd(related?.id)}
															className="btn form-control"
														>
															<i className="fa fa-shopping-cart"></i>
															&nbsp; Add To Cart
														</button>
													</>
												)}
											</div>
										</div>
									</Link>
								</div>
							</div>
						);
					})}
				</>
			)}
		</>
	);
};

export default RelatedProduct;

{
	/* <button
		onClick={() => handleAdd(related)}
		className="btn form-control"
	>
		<i className="fa fa-shopping-cart"></i>
		Add To Cart
	</button> */
}
