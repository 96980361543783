import { Link } from "@mui/material";
import React, { useEffect } from "react";
import {
	CardImg2,
	CardImg3,
	CateImg2,
	ProBannerImg1,
} from "../../constant/Index";

const FeaturePro = (props) => {
	let CardMap = [
		{
			id: 1,
			thumbnail: CardImg2,
			name: "Vitamins & Supplements",
			ProdName: "Lorem Ipsum is simply dummy text",
			rating: 5,
			unit_price: 49.0,
			purchase_price: 55.0,
		},
		{
			id: 2,
			thumbnail: ProBannerImg1,
			name: "Vitamins & Supplements",
			ProdName: "Lorem Ipsum is simply dummy text",
			rating: 5,
			unit_price: 49.0,
			purchase_price: 55.0,
		},
		{
			id: 3,
			thumbnail: CateImg2,
			name: "Vitamins & Supplements",
			ProdName: "Lorem Ipsum is simply dummy text",
			rating: 5,
			unit_price: 49.0,
			purchase_price: 55.0,
		},
		{
			id: 4,
			thumbnail: CardImg3,
			name: "Vitamins & Supplements",
			ProdName: "Lorem Ipsum is simply dummy text",
			rating: 5,
			unit_price: 49.0,
			purchase_price: 55.0,
		},
		{
			id: 5,
			thumbnail: CardImg2,
			name: "Vitamins & Supplements",
			ProdName: "Lorem Ipsum is simply dummy text",
			rating: 5,
			unit_price: 49.0,
			purchase_price: 55.0,
		},
		{
			id: 6,
			thumbnail: ProBannerImg1,
			name: "Vitamins & Supplements",
			ProdName: "Lorem Ipsum is simply dummy text",
			rating: 5,
			unit_price: 49.0,
			purchase_price: 55.0,
		},
		{
			id: 7,
			thumbnail: CateImg2,
			name: "Vitamins & Supplements",
			ProdName: "Lorem Ipsum is simply dummy text",
			rating: 5,
			unit_price: 49.0,
			purchase_price: 55.0,
		},
		{
			id: 8,
			thumbnail: CardImg3,
			name: "Vitamins & Supplements",
			ProdName: "Lorem Ipsum is simply dummy text",
			rating: 5,
			unit_price: 49.0,
			purchase_price: 55.0,
		},
	];

	return (
		<>
			{CardMap.map((prodata, index) => {
				return (
					<div key={prodata?.id} className="col-lg-3">
						<div className="single-product-card-wrapper WrapperCardDiv">
							<Link className="lnk">
								<div className="single-producrt-img-wrapper">
									<figure>
										<img
											src={prodata?.thumbnail}
											className="img-fluid"
											alt=""
										/>
									</figure>
								</div>
							</Link>
							<div className="single-product-content-wrapper">
								{/* <h6 className="single-product-category">lorem</h6> */}
								<p>{prodata?.name}</p>
								<h5 className="single-product-name">{prodata?.ProdName}</h5>
								<div className="review-wrapper">
									<ul>
										{[...Array(prodata?.rating)].map((e, i) => (
											<li key={i}>
												<i className="fa fa-star"></i>
											</li>
										))}
										<li className="reviewsSize">(119 reviews)</li>
									</ul>
								</div>
								<div className="price-wrapper">
									<h6 className="price">
										${" "}
										{prodata?.unit_price !== 0
											? Math.round(prodata?.unit_price)
											: prodata?.purchase_price}
										<span className="regular-price">
											${" "}
											{prodata?.unit_price == 0
												? prodata?.unit_price
												: prodata?.purchase_price}
										</span>
									</h6>
								</div>
								<div className="cart-button-wrapper">
									<button
										type="button"
										className="btn form-control form-control2"
									>
										<i className="fa fa-shopping-cart"></i>
										&nbsp; Add To Cart
									</button>
								</div>
							</div>
						</div>
					</div>
				);
			})}
		</>
	);
};

export default FeaturePro;
