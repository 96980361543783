import React, { useEffect } from "react";
import { add } from "../../store/cartSlice";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
// import { fetchProducts } from "../../store/productSlice";
import { useSelector } from "react-redux";
import { AddCart, STATUSES } from "../../store/productSlice";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Spinner from "../Spinner/Spinner";
import Chip from "@mui/material/Chip";
import { toast } from "react-toastify";

const Products = (props) => {
	const { ProductData, isLoading } = useSelector((state) => state.product);
	const dispatch = useDispatch();

	// useEffect(() => {
	// 	window.scrollTo(0, 0);
	// }, []);
	const { Cart } = useSelector((state) => state.product);
	const handleAdd = (id) => {
		let checkItemAlreadyExist = Cart.filter((val) => val?.product_id === id);
		if (checkItemAlreadyExist.length > 0) {
			toast.error("Item Already Exist in Cart!!!");
		} else {
			let quantity = 1;
			const formData = new FormData();
			formData.append("id", id);
			formData.append("quantity", quantity);
			dispatch(AddCart(formData));
		}
	};

	// if (status === STATUSES.LOADING) {
	// 	return (
	// 		<>
	// 			<Box
	// 				sx={{
	// 					display: "flex",
	// 					width: "100%",
	// 					justifyContent: "center",
	// 					marginTop: "30px",
	// 					height: "100%",
	// 				}}
	// 			>
	// 				<CircularProgress />
	// 			</Box>
	// 		</>
	// 	);
	// }

	if (isLoading) {
		return (
			<>
				<Box
					sx={{
						display: "flex",
						width: "100%",
						justifyContent: "center",
						marginTop: "30px",
						height: "100%",
					}}
				>
					<CircularProgress />
				</Box>
			</>
		);
	}

	const { page } = props;
	return (
		<>
			{!isLoading ? (
				ProductData?.products?.map((prodata, index) =>
					index <
					(props.limit ? props.limit : ProductData?.products?.length) ? (
						<div
							key={prodata?.id}
							className={page == "shop" ? "col-lg-4" : "col-lg-3"}
						>
							<div className="single-product-card-wrapper">
								{prodata?.current_stock <= 0 ? (
									<>
										<Chip
											label="Out Of Stock"
											style={{
												position: "absolute",
												top: "30px",
												left: "30px",
												background: "red",
												color: "white",
												width: "37%",
												height: "27px",
											}}
										/>
									</>
								) : null}
								<Link to={`/product-detail/${prodata?.id}`}>
									<div className="single-producrt-img-wrapper">
										<figure>
											<img
												src={prodata?.thumbnail}
												className="img-fluid"
												alt=""
											/>
										</figure>
									</div>
									<div className="single-product-content-wrapper">
										{/* <h6 className="single-product-category">lorem</h6> */}
										<h5 className="single-product-name">{prodata?.name}</h5>
										<div className="review-wrapper">
											<ul>
												{prodata?.rating[0]?.average ? (
													<>
														{[
															...Array(
																parseInt(
																	prodata?.rating[0]?.average.split(".")[0] ||
																		0,
																),
															).keys(),
														].map((starrr) => {
															return (
																<li key={`star_${starrr}`}>
																	{console.log(starrr.length)}
																	<i className="fa fa-star"></i>
																</li>
															);
														})}
													</>
												) : (
													<>
														<i class="fa fa-star-o" aria-hidden="true"></i>
														<i class="fa fa-star-o" aria-hidden="true"></i>
														<i class="fa fa-star-o" aria-hidden="true"></i>
														<i class="fa fa-star-o" aria-hidden="true"></i>
														<i class="fa fa-star-o" aria-hidden="true"></i>
													</>
												)}
												<span className="total-reviews"></span>
											</ul>
										</div>

										<div className="price-wrapper">
											<h6 className="price">
												$
												{prodata?.discountedprice !== 0
													? Math.round(prodata?.discountedprice)
													: prodata?.unit_price}
												<span className="regular-price">
													$
													{prodata?.discountedprice == 0
														? prodata?.discountedprice
														: prodata?.unit_price}
												</span>
											</h6>
										</div>
										<div className="cart-button-wrapper">
											{prodata?.current_stock == 0 ? (
												<>
													<button
														type="button"
														className="btn form-control"
														style={{
															background: "#1aac7a",
															color: "white",
															cursor: "not-allowed",
														}}
														disabled
													>
														<i className="fa fa-shopping-cart"></i>
														&nbsp; Add To Cart
													</button>
												</>
											) : (
												<>
													<button
														type="button"
														onClick={() => handleAdd(prodata?.id)}
														className="btn form-control"
													>
														<i className="fa fa-shopping-cart"></i>
														&nbsp; Add To Cart
													</button>
												</>
											)}
										</div>
									</div>
								</Link>
							</div>
						</div>
					) : (
						<div key={index + 1000}></div>
					),
				)
			) : (
				<Spinner />
			)}
		</>
	);
};

export default Products;
