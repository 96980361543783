import React from "react";
import { Link } from "react-router-dom";
import { CateImg1, CateImg2, CateImg3 } from "../../constant/Index";

function HomeProductPage() {
	let CardMap = [
		{
			id: 1,
			thumbnail: CateImg1,
			name: "Vitamins & Supplements",
			ProdName: "Lorem Ipsum is simply dummy text",
			rating: 5,
			unit_price: 49.0,
			purchase_price: 55.0,
		},
		{
			id: 2,
			thumbnail: CateImg2,
			name: "Vitamins & Supplements",
			ProdName: "Lorem Ipsum is simply dummy text",
			rating: 5,
			unit_price: 49.0,
			purchase_price: 55.0,
		},
		{
			id: 3,
			thumbnail: CateImg3,
			name: "Vitamins & Supplements",
			ProdName: "Lorem Ipsum is simply dummy text",
			rating: 5,
			unit_price: 49.0,
			purchase_price: 55.0,
		},
		{
			id: 4,
			thumbnail: CateImg1,
			name: "Vitamins & Supplements",
			ProdName: "Lorem Ipsum is simply dummy text",
			rating: 5,
			unit_price: 49.0,
			purchase_price: 55.0,
		},
		{
			id: 5,
			thumbnail: CateImg2,
			name: "Vitamins & Supplements",
			ProdName: "Lorem Ipsum is simply dummy text",
			rating: 5,
			unit_price: 49.0,
			purchase_price: 55.0,
		},
		{
			id: 6,
			thumbnail: CateImg3,
			name: "Vitamins & Supplements",
			ProdName: "Lorem Ipsum is simply dummy text",
			rating: 5,
			unit_price: 49.0,
			purchase_price: 55.0,
		},
	];
	return (
		<>
			<section className="mainContainerCate">
				<div className="container mainContainerCate">
					<div className="row ">
						<div className="col-lg-3 ">
							<div className="first_section_heading">
								<div className="first_section_btn">
									<Link to="#">
										<i class="fa fa-times" style={{ color: "#fff" }}></i>
									</Link>
								</div>
								<div className="first_section_para">
									<h3 className="Cate">Categories</h3>
								</div>
							</div>
							<div className="first_section_category">
								<ul>
									<li>
										<span className="addIconCate">
											<i class="fa fa-plus"></i>
										</span>
										<span>
											<p className="addParaCate">Health & Wellness</p>
										</span>
									</li>
									<li>
										<span className="addIconCate">
											<i class="fa fa-plus"></i>
										</span>
										<span>
											<p className="addParaCate"> Vitamins & Supplement</p>
										</span>
									</li>
									<li>
										<span className="addIconCate">
											<i class="fa fa-plus"></i>
										</span>
										<span>
											<p className="addParaCate"> Sports Nutrition</p>
										</span>
									</li>
									<li>
										<span className="addIconCate">
											<i class="fa fa-plus"></i>
										</span>
										<span>
											<p className="addParaCate">Herbs & Natural Remedies</p>
										</span>
									</li>
									<li>
										<span className="addIconCate">
											<i class="fa fa-plus"></i>
										</span>
										<span>
											<p className="addParaCate">Food and Drinks</p>
										</span>
									</li>

									<li>
										<span className="addIconCate">
											<i class="fa fa-plus"></i>
										</span>
										<span>
											<p className="addParaCate">Offers</p>
										</span>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-9 HomeCateCard">
							{CardMap?.map((prodata, index) => (
								<div key={prodata?.id} className="col-lg-4">
									<div className="single-product-card-wrapper double-product-card-wrapper">
										<Link to={`/product-detail/${prodata?.id}`}>
											<div className="single-producrt-img-wrapper">
												<figure>
													<img
														src={prodata?.thumbnail}
														className="img-fluid"
														alt=""
													/>
												</figure>
											</div>
											<div className="single-product-content-wrapper">
												<p>{prodata?.name}</p>
												<h5 className="single-product-name">
													{prodata?.ProdName}
												</h5>
												<div className="review-wrapper">
													<ul>
														{prodata?.rating ? (
															<>
																{[
																	...Array(
																		parseInt(prodata?.rating || 0),
																	).keys(),
																].map((starrr) => {
																	return (
																		<>
																			<li key={`star_${starrr}`}>
																				<i className="fa fa-star"></i>
																			</li>
																		</>
																	);
																})}
																<li className="reviewsSize">(119 reviews)</li>
															</>
														) : (
															<>
																<i class="fa fa-star-o" aria-hidden="true"></i>
																<i class="fa fa-star-o" aria-hidden="true"></i>
																<i class="fa fa-star-o" aria-hidden="true"></i>
																<i class="fa fa-star-o" aria-hidden="true"></i>
																<i class="fa fa-star-o" aria-hidden="true"></i>
															</>
														)}
														<span className="total-reviews"></span>
													</ul>
												</div>
												<div className="price-wrapper">
													<h6 className="price">
														${prodata?.unit_price}
														<span className="regular-price">
															${prodata?.purchase_price}
														</span>
													</h6>
												</div>
												<div className="cart-button-wrapper">
													{prodata?.current_stock == 0 ? (
														<>
															<button
																type="button"
																className="btn form-control form-control2"
																style={{
																	background: "#1aac7a",
																	color: "white",
																	cursor: "not-allowed",
																}}
																disabled
															>
																<i className="fa fa-shopping-cart"></i>
																&nbsp; Add To Cart
															</button>
														</>
													) : (
														<>
															<button
																type="button"
																className="btn form-control form-control2"
															>
																<i className="fa fa-shopping-cart"></i>
																&nbsp; Add To Cart
															</button>
														</>
													)}
												</div>
											</div>
										</Link>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default HomeProductPage;
