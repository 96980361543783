import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { InnerBannerImg } from "../../constant/Index";
import { add } from "../../store/cartSlice";
import { STATUSES } from "../../store/productSlice";
import Spinner from "../../components/Spinner/Spinner";

const SubCategory = () => {
	const isData = JSON.parse(sessionStorage.getItem("searchData"));
	const dispatch = useDispatch();
	const subProduct = useSelector((state) => state.product?.SubData);
	const { status } = useSelector((state) => state.product);

	const handleAdd = (data) => {
		dispatch(add(data));
	};

	if (status == STATUSES.LOADING) {
		return (
			<>
				<Spinner />
			</>
		);
	}
	return (
		<>
			{/* Header */}
			<Header />
			{/* Header */}

			{/* InnerBanner */}
			<section className="universal_banner">
				<div className="container-fluid p-0">
					<div className="banner-content">
						<div className="banner_image">
							<figure>
								<img src={InnerBannerImg} className="img-fluid" />
							</figure>
						</div>
						<div className="page-title">
							<h2 className="title">Category products</h2>
						</div>
					</div>
				</div>
			</section>
			{/* InnerBanner */}

			{/* Product */}
			<section className="sub-categpory">
				<div className="container">
					<div className="row">
						{isData.length == 0 ? (
							<>
								<h5 className="w-100 text-center py-5">
									No data in this category
								</h5>
							</>
						) : (
							<>
								{Object.values(subProduct)
									.slice(0, -4)
									?.map((data, index) => {
										return (
											<div key={index} className="col-lg-3">
												<div className="single-product-card-wrapper">
													<Link to={`/product-detail/${data?.id}`}>
														<div className="single-producrt-img-wrapper">
															<figure>
																<img
																	src={data?.thumbnail}
																	className="img-fluid"
																	alt=""
																/>
															</figure>
														</div>
														<div className="single-product-content-wrapper">
															{/* <h6 className="single-product-category">lorem</h6> */}
															<h5 className="single-product-name">
																{data?.name}
															</h5>
															<div className="review-wrapper">
																<ul>
																	{data?.rating?.average ? (
																		<>
																			{[
																				...Array(
																					parseInt(
																						data?.rating?.average.split(".") ||
																							0,
																					),
																				).keys(),
																			].map((starrr) => {
																				return (
																					<li key={`star_${starrr}`}>
																						<i className="fa fa-star"></i>
																					</li>
																				);
																			})}
																		</>
																	) : (
																		<>
																			<span>No rating</span>
																		</>
																	)}
																	<span className="total-reviews"></span>
																</ul>
															</div>
															<div className="price-wrapper">
																<h6 className="price">
																	${data?.unit_price}
																	<span className="regular-price">
																		${data?.purchase_price}
																	</span>
																</h6>
															</div>
															<div className="cart-button-wrapper">
																{data?.current_stock == 0 ? (
																	<>
																		<button
																			type="button"
																			className="btn form-control"
																		>
																			<i className="fa fa-shopping-cart"></i>
																			out of stock
																		</button>
																	</>
																) : (
																	<>
																		<button
																			type="button"
																			onClick={() => handleAdd(data)}
																			className="btn form-control"
																		>
																			<i className="fa fa-shopping-cart"></i>
																			Add To Cart
																		</button>
																	</>
																)}
															</div>
														</div>
													</Link>
												</div>
											</div>
										);
									})}
							</>
						)}
					</div>
				</div>
			</section>
			{/* Product */}

			{/* Footer */}
			<Footer />
			{/* Footer */}
		</>
	);
};

export default SubCategory;
