import React, { useEffect } from "react";
import { footerlogo } from "../../constant/Index";
import { Link, useNavigate } from "react-router-dom";
import Newsletter from "../Newsletter/Newsletter";
import { useSelector } from "react-redux/es/exports";

const Footer = () => {
	const navigate = useNavigate();
	const data = useSelector((state) => state.data);
	return (
		<>
			{/* Newsletter */}
			<Newsletter />
			{/* Newsletter */}
			<footer className="footer">
				<div className="container">
					<div className="row pb-5">
						<div className="col-lg-5 ">
							<div className="footer-1-content-wrapper footerline">
								<Link to="#">
									<img src={footerlogo} className="img-fluid" alt="" />
								</Link>
								{/* <div className="borderLeftFooter"></div> */}
							</div>
						</div>
						<div className="col-lg-7">
							<div className="row">
								<div className="col-lg-4">
									<div className="footer-widget-2 footerline">
										<h5>Company Information</h5>
										<div className="footer-links-wrapper">
											<ul>
												<li>
													<Link to="/about-us">About us</Link>
												</li>
												<li>
													{/* #our_mission */}
													<a
														href="#our_mission"
														onClick={() => navigate("/about-us")}
													>
														Mission
													</a>
												</li>
												<li>
													<a
														href="#our_vission"
														onClick={() => navigate("/about-us")}
													>
														Vision
													</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
								<div className="col-lg-4">
									<div className="footer-widget-3 footerline">
										<h5>Customer Services</h5>
										<div className="footer-links-wrapper">
											<ul>
												<li>
													<Link to="blog-detail.php">Delivery Information</Link>
												</li>
												<li>
													<Link to="blog-detail.php">Privacy Policy</Link>
												</li>
												<li>
													<Link to="blog-detail.php">
														Returns &amp; Refunds Policy
													</Link>
												</li>
												<li>
													<Link to="blog-detail.php">Terms and Conditions</Link>
												</li>
												<li>
													<Link to="blog-detail.php">Cookies policies</Link>
												</li>
											</ul>
										</div>
									</div>
								</div>
								<div className="col-lg-4">
									<div className="footer-widget-4">
										<h5>Contact Us</h5>
										<div className="footer-links-wrapper">
											<ul>
												<li>
													<a href="mailto:Loremipsum@gmail.com">
														<i className="fa fa-envelope"></i>{" "}
														Sales@nutrients24.com
													</a>
												</li>
												<li>
													<a href="mailto:Loremipsum@gmail.com">
														<i className="fa fa-envelope"></i>
														Info@nutrients24.com
													</a>
												</li>
											</ul>
											<ul className="social-links d-flex">
												<li>
													<Link to="#">
														<i className="fa fa-facebook"></i>
													</Link>
												</li>
												<li>
													<Link to="#">
														<i className="fa fa-twitter"></i>
													</Link>
												</li>
												<li>
													<Link to="#">
														<i className="fa fa-instagram"></i>
													</Link>
												</li>
												<li>
													<Link to="#">
														<i className="fa fa-linkedin"></i>
													</Link>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="copy-right-wrapper">
					<div className="container">
						<div className="row">
							<div className="col-lg-12 copyRightCenter">
								<p>
									©Copyright Nutritions24.com {new Date().getFullYear()}. All
									rights reserved.
								</p>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	);
};

export default Footer;
